import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation("404");
  return (
    <Layout>
      <GatsbySeo title="404: Not found" />
      <div
        style={{
          minHeight: "500px",
          maxWidth: "1280px",
          width: "90%",
          padding: "80px 0px",
          margin: "0 auto",
        }}
      >
        <h1>{t("notFound")}</h1>
        <p>{t("message")}</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
